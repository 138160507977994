import React, {useState} from "react"


const HomepageHelps = () => {
    const items = [
        {
            title: "Gaming",
            description: "TRON is making waves in the gaming world by enabling the creation of exciting new play-to-earn (P2E) games across all its platforms. As a gamer, you know that the quality of your gaming experience is everything. That's why TRON is dedicated to working with developers every day to scale and perfect their games, ensuring that every player can enjoy a seamless, immersive experience like never before. With TRON, the future of gaming is here – so don't miss out on the action!",
            url: "/resources/gaming",
            disabled: false
        },
        {
            title: "Finance",
            description: "TRON is empowering developers with the tools they need to create the next generation of DeFi applications. So if you're looking for a DeFi platform that is paving the way for a more open, transparent, and decentralized financial future, look no further than TRON. Join us today and be a part of the revolution!",
            url: "/resources/finance",
            disabled: false
        },
        {
            title: "Multimedia",
            description: "TRON is setting the scene for the future of media by offering the first free, peer-to-peer, decentralized content entertainment protocol. At TRON, we're proud to be constructing an open ecosystem that allows everyone to freely publish, store, and own data. This creates a decentralized media entertainment ecosystem for all, where users have more control over their multimedia experience than ever before.",
            url: "/resources/multimedia",
            disabled: false
        },
        {
            title: "Art",
            description: "We believe artists and creators deserve fair compensation and complete control over their intellectual property. On TRON, distribution happens faster and more efficiently than ever before, allowing artists to reach new audiences and engage in new markets. Our commitment to empowering artists compels us to develop decentralized solutions creators can use for commerce and community with followers and fans.",
            url: "/resources/art",
            disabled: false
        },
        {
            title: "Governance",
            description: "With its innovative, proof-of-stake, consensus protocol, TRON puts the power in the hands of token holders. We believe in creating a system that provides opportunity for all, where decisions are made based on the will of the community. That's why we're proud to be leading the pack when it comes to decentralized governance. And if that wasn't enough, TRON is also the official blockchain for the Commonwealth of Dominica, a testament to the platform's strength and reliability.",
            url: "/resources/governance",
            disabled: false
        }
    ]
    const [selectedItem, setSelectedItem] = useState(items[0]);

    const selectItem = function (index) {
        setSelectedItem(items[index]);
    }

    const isSelected = function (title) {
        return title === selectedItem.title ? " homepage-helps-inner-circle-fill" : " ";
    }

    return (
        <div className="homepage-helps-inner mx-auto my-5 ">
            <div className="row no-gutters py-5 justify-content-center">
                <div
                    className="col-12 col-lg-6 offset-0 offset-lg-0 px-md-5 px-2 order-lg-0 order-1 mt-5 text-center text-lg-left">
                    HOW <span className="homepage-helps-inner-tron">TRON</span> HELPS
                    <hr/>
                    <h3>{selectedItem.title}</h3>
                    <hr/>
                    <p>{selectedItem.description}
                    </p>
                    <a href={selectedItem.url} id={"home-how-tron-helps-" + selectedItem.title + "-visit"}
                       onClick={function (e) {
                           if (selectedItem.disabled === true) {
                               e.preventDefault();
                           }
                       }}>
                        <button id={"home-how-tron-helps-" + selectedItem.title + "-visit-btn"}
                                className="homepage-helps-inner-learn"
                                disabled={selectedItem.disabled}>&gt; Visit {selectedItem.title}</button>
                    </a>
                    {selectedItem.disabled === true &&
                        <h4 className="text-center text-lg-left pl-0 pl-lg-5">Coming Soon!</h4>}
                </div>
                <div
                    className="col-12 col-lg-6 offset-0 offset-lg-0 order-lg-1 order-0 d-inline-flex d-lg-block justify-content-center my-5 homepage-helps-inner-buttons p-2">
                    <div className="d-flex justify-content-between flex-wrap mt-lg-5 mt-0">
                        <div id={"home-how-tron-helps-" + items[0].title}
                             className={"homepage-helps-inner-circle " + isSelected(items[0].title)}
                             aria-labelledby={"how tron helps " + items[0].title}
                             role={"button"}
                             tabIndex="0"
                             onKeyDown={function (e) {
                                 if (e.key === "Enter") {
                                     selectItem(0)
                                 }
                             }}
                             onClick={function () {
                                 selectItem(0)
                             }}>{items[0].title}</div>
                        <div id={"home-how-tron-helps-" + items[1].title}
                             aria-labelledby={"how tron helps " + items[1].title}
                             role={"button"}
                             tabIndex="0"
                             onKeyDown={function (e) {
                                 if (e.key === "Enter") {
                                     selectItem(1)
                                 }
                             }}
                             className={"homepage-helps-inner-circle " + isSelected(items[1].title)}
                             onClick={function () {
                                 selectItem(1)
                             }}>{items[1].title}</div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div id={"home-how-tron-helps-" + items[2].title}
                             aria-labelledby={"how tron helps " + items[2].title}
                             role={"button"}
                             tabIndex="0"
                             onKeyDown={function (e) {
                                 if (e.key === "Enter") {
                                     selectItem(2)
                                 }
                             }}
                             className={"homepage-helps-inner-circle " + isSelected(items[2].title)}
                             onClick={function () {
                                 selectItem(2)
                             }}>{items[2].title}</div>
                    </div>
                    <div className="d-flex justify-content-end justify-content-lg-between flex-wrap">
                        <div id={"home-how-tron-helps-" + items[3].title}
                             aria-labelledby={"how tron helps " + items[3].title}
                             role={"button"}
                             tabIndex="0"
                             onKeyDown={function (e) {
                                 if (e.key === "Enter") {
                                     selectItem(3)
                                 }
                             }}
                             className={"homepage-helps-inner-circle " + isSelected(items[3].title)}
                             onClick={function () {
                                 selectItem(3)
                             }}>{items[3].title}</div>
                        <div id={"home-how-tron-helps-" + items[4].title}
                             aria-labelledby={"how tron helps " + items[4].title}
                             role={"button"}
                             tabIndex="0"
                             onKeyDown={function (e) {
                                 if (e.key === "Enter") {
                                     selectItem(4)
                                 }
                             }}
                             className={"homepage-helps-inner-circle" + isSelected(items[4].title)}
                             onClick={function () {
                                 selectItem(4)
                             }}>{items[4].title}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageHelps