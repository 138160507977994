import React, { useEffect, useState } from "react"
import background from "../../images/home/cities.mp4";
import * as numeral from 'numeral';
const HomeHero = () => {
    const [data,setData] = useState({})
    useEffect(()=>{
        const ws = new WebSocket('wss://apilist.tronscanapi.com/api/tronsocket');
        ws.onopen = function(ev){
            console.log('onopen',ev)
        }
        ws.onmessage = function(ev){
            try{
                setData(JSON.parse(ev.data))
            }catch(e){
                console.log('error',e)
            }
        }
        return ()=>{
            ws.close()
        }
    },[])
    useEffect(()=>{
        console.log('data',data)
    },[data])
    return (
        <>
            <h1 className="w-100 text-center homepage-hero-title px-3">
                #BUIDLtheFUTURE with TRON
            </h1>
            <section className="homepage-hero">
                <video autoPlay muted loop playsInline className="homepage-hero-background">
                    <source src={background} type="video/mp4"/>
                </video>
                <div className="homepage-hero-background-overlay">
                    <div
                        className="row no-gutters text-center align-items-center homepage-hero-top homepage-hero-city-text">
                        <div className="col-4 offset-1">
                            <p>Number of Accounts</p>
                            <span>{numeral(data.account_number).format('$0,0')}</span>
                        </div>
                        <div className="col-4 offset-2">
                            <p>Transaction Count</p>
                            <span>{numeral(data.transaction_number).format('$0,0')}</span>
                        </div>
                    </div>
                    <div
                        className="row no-gutters text-center align-items-center homepage-hero-bottom homepage-hero-city-text">
                        <div className="col-4 offset-1">
                            <p>Transfer Amount of Core Tokens</p>
                            <span>{numeral(data.vip_core_token_amount_his_total).format('$0,0')}</span>
                        </div>

                        <div className="col-4 offset-2">
                            <p>Token Value Locked</p>
                            <span>{numeral(data.locked_number).format('$0,0')}</span>
                        </div>
                    </div>
                </div>


            </section>
        </>
    )
}

export default HomeHero