import React from "react";
import {Helmet} from "react-helmet";
import tronbullCoins from "../images/home/tronbull-coins.svg"
import defi from "../images/home/defi.svg"
import nft from "../images/home/nft.svg"
import gamefi from "../images/home/gamefi.svg"
import web3 from "../images/home/web3.svg"
import coins from "../images/home/coins.svg"
import tronbullLearn from "../images/home/tronbull-learn.gif"
import tronbullVideo from "../images/home/tronbull-video.gif"
import tronbullExperiment from "../images/home/tronbull-puzzle.gif"
import tronbullPlay from "../images/home/tronbull-play.gif"
import tronbullWave from "../images/home/tronbull-wave.svg"
import HomeHero from "../components/homepage/home-hero";
import playButton from "../images/home/play-button.svg"
import HomepageHelps from "../components/homepage/HomepageHelps";
import EmailCapture from "../components/EmailCapture";
import HomepageLogo from '../images/hackatrons6/homepage-logo.png';
import HackathonLogo from "../images/hackatrons6/hackatron-logo-sm.png";
import chaingpt from '../images/hackatrons6/chaingpt.png';
import solidus from '../images/hackatrons6/solidus.png';
import ankr from '../images/hackatrons6/ankr.png';
import GoogleCloud from '../images/hackatrons7/Google_Cloud.png'
const Index = ({ data }) => {

    return (

        <div className="homepage pb-5">
            <Helmet>
                <meta name="description" content="Decentralized internet using blockchain technology and powering decentralized apps (DApps). TRON empowers developers to be creative. Build on TRON."/>
                <meta property="og:description" content="Decentralized internet using blockchain technology and powering decentralized apps (DApps). TRON empowers developers to be creative. Build on TRON."/>
                <meta property="og:title" content="Decentralized Autonomous Organization DAO | TRONDAO"/>
            </Helmet>
            <div className="homepage-hackathon-banner mb-5 d-flex flex-row align-items-center text-center row">
                <div className="col-md-auto col-12 homepage-hackathon-banner-one text-center text-md-left">
                    <img src={HackathonLogo} /><span className="homepage-hackathon-banner-spacer mx-4"/><span>Season 7</span>
                </div>
                <div className="col-md-auto col-12 homepage-hackathon-banner-two text-center text-md-left">
                    <img src={HomepageLogo} />
                </div>
                <div className="col-md-auto col-12 homepage-hackathon-banner-three text-center text-md-left">
                    {/* <img src={chaingpt}/>
                    <img src={solidus}/>
                    <img src={ankr}/> */}
                    <img src={GoogleCloud} style={{height:'84px'}}/>
                </div>
                <div className="col-md-auto col-12 pb-md-0 flex-fill">
                    <div className="d-flex justify-content-center justify-content-lg-end align-items-center pt-md-2 pb-md-2">
                        <a href="/hackatron/" id="homepage-hackathon-banner-button">
                            <button className="homepage-hackathon-banner-btn">Learn More</button>
                        </a>
                    </div>
                </div>

            </div>
            <HomeHero/>
            <div className="row no-gutters p-5 my-5">
                <div className="col-lg-5 offset-lg-1 offset-0 col-md-6 col-12 align-items-center order-2 order-md-1">
                    <div className="order-2 order-md-0">
                        <div className="homepage-introduction-header">TRON is Here to Help</div>
                        <div className="homepage-introduction-content mt-4">TRON exists to empower decentralized commerce and community for every human on the planet. Since its inception in 2017, the TRON network has remained focused on two ideals: decentralization and empowerment. We enable the development of decentralized financial and social applications, and we empower developers and users alike to own this purpose with us. In December 2021, TRON became TRON DAO, a community-governed, decentralized, autonomous organization connected to a diverse and growing blockchain ecosystem. </div>
                        <div className="homepage-introduction-content mt-2">Come #BUIDLtheFUTURE with us, and make TRON the way the world spends, receives, and settles money.</div>
                    </div>
                    <div className="py-5 text-center">
                        <a href="https://forms.gle/U5VH818cRvWNeXsHA" target="_blank" rel="noopener noreferrer" id="homepage-buttons-apply-funding">
                            <button className="homepage-apply-funding-button">> TRON DAO Ventures</button>
                        </a>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 align-items-center text-center order-1 order-md-2 mb-5">
                    <img src={tronbullCoins} className="homepage-introduction-icon pt-3 img-fluid" alt="TRON Bull Decentralizing the Web"/>
                </div>
            </div>
            {/* <div className="homepage-rounded px-5">
                <div className="homepage-grants-header col-lg-5 offset-lg-1 offset-0">TRON DAO GRANTS</div>
                <div className="homepage-grants-subheader col-lg-5 offset-lg-1 offset-0 col-12">The TRON DAO Grant Program provides support to Communities, Developers, and Influencers that are building on the TRON network.
                </div>
                <div className="row no-gutters mt-5 text-center">
                    <div className="col-md-4 col-12 align-items-center mb-5">
                        <div className="homepage-grants-container-icons text-center">
                            <img src={developer} className="img-fluid" alt="TRON Bull Community Ambassador"/>
                        </div>
                        <div className="homepage-grants-container-header" >Ambassador</div>
                        <a href="/ambassador-grants/#grants-community" id="homepage-button-grants-community">
                            <button className="homepage-grants-container-apply-button mt-5">> APPLY</button>
                        </a>
                    </div>
                    <div className="col-md-4 col-12 align-items-center mb-5">
                        <div className="homepage-grants-container-icons text-center">
                            <img src={community} className="img-fluid" alt="TRON Bull Developer"/>
                        </div>
                        <div className="homepage-grants-container-header" >Developer</div>
                        <a href="/ambassador-grants/#grants-developers" id="homepage-button-grants-developer">
                            <button className="homepage-grants-container-apply-button mt-5">> APPLY</button>
                        </a>
                    </div>
                    <div className="col-md-4 col-12 align-items-center mb-5">
                        <div className="homepage-grants-container-icons text-center">
                            <img src={influencer} className="img-fluid" alt="TRON Bull Influencer"/>
                        </div>
                        <div className="homepage-grants-container-header homepage-grants-container-header-influencer" >Influencer</div>
                        <a href="/ambassador-grants/#grants-influencers" id="homepage-button-grants-influencer">
                            <button className="homepage-grants-container-apply-button homepage-grants-container-apply-button-influencer mt-5">> APPLY</button>
                        </a>
                    </div>
                </div>
            </div> */}
            <div className="homepage-helps py-5 my-5">

                <HomepageHelps/>


            </div>
            {/* <div className="homepage-rounded">
                <div className="row no-gutters mt-5 p-5">
                    <div className="col-lg-5 offset-lg-1 offset-0 col-md-6 col-12 align-items-center py-5">
                        <div className="homepage-hackthon-header">HACKATHON</div>
                        <div className="homepage-introduction-content mt-4 mb-4">Are you a developer or a blockchain startup looking to make your mark in the world of Web3? The TRON Grand Hackathon provides the grandest opportunities for promising projects to accelerate the development of decentralized storage, decentralized applications, digital assets, cryptocurrency wallets, and more, all while promoting the growth of the blockchain ecosystem. Participation in the HackaTRON gives builders the chance to showcase their talents, network with industry experts, and #BUIDLtheFUTURE together with TRON.</div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 align-items-center">
                        <div className="homepage-hackthon-details-content">$500k USDD prize pool</div>
                        <div className="homepage-hackthon-details-spacer align-items-center" />
                        <div className="homepage-hackthon-details-content">30+ partners</div>
                        <div className="homepage-hackthon-details-spacer align-items-center" />
                        <div className="homepage-hackthon-details-content">60+ Judges</div>
                        <div className="homepage-hackthon-details-learn-more">
                            <a href="/hackatron/" id="homepage-button-hackathon-learn-more">
                                <button className="homepage-hackthon-details-learn-more-button">> LEARN MORE</button>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row no-gutters my-5 pb-5 text-center">
                    <div className="col-lg-3 col-md-6 col-12 text-center">
                        <img src={defi} className="homepage-hackthon-icons" alt="TRON DAO DeFi"/>
                        <h4 className="pt-2">Defi</h4>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 text-center">
                        <img src={nft} className="homepage-hackthon-icons" alt="TRON DAO NFT"/>
                        <h4 className="pt-2">NFT</h4>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 text-center">
                        <img src={gamefi} className="homepage-hackthon-icons" alt="TRON DAO GameFi"/>
                        <h4 className="pt-2">GameFi</h4>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 text-center">
                        <img src={web3} className="homepage-hackthon-icons" alt="TRON DAO Web3"/>
                        <h4 className="pt-2">Web3</h4>
                    </div>
                </div>
            </div> */}


            <div className="homepage-starting-header-start mt-5 px-5 text-center">
                <img src={playButton} className="homepage-starting-header-icon" alt="play button"/>
                Start
            </div>
            <div className="homepage-starting-header mt-5 px-5 text-center homepage-starting-header-building">Building with TRON</div>
            <div className="homepage-starting-wrapper">
                <div className="row no-gutters mt-5 px-5 homepage-starting-rows">
                    <div className="homepage-starting-leftangle" />
                    <div className="col-lg-5 offset-lg-1 offset-0 col-md-12 col-12 align-items-center text-center px-3">
                        <img src={tronbullLearn} className="homepage-starting-icons img-fluid" alt="TRON Bull Learn Development"/>
                        <div className="homepage-starting-container text-left">
                            <div className="homepage-starting-container-header">TRON Infrastructure</div>
                            <div className="homepage-starting-container-content">
                                TRON's detailed guides, written by current developers for future developers, help builders explore TRON's unique architecture and core applications. We believe in providing our community with the tools and knowledge they need to #BUIDLtheFUTURE on TRON, empowering developers to build the next generation of blockchain solutions.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-12 align-items-center text-center px-3">
                        <img src={tronbullVideo} className="homepage-starting-icons img-fluid" alt="TRON Bull Learning Tutorials"/>
                        <div className="homepage-starting-container text-left">
                            <div className="homepage-starting-container-header">TRON Tutorials</div>
                            <div className="homepage-starting-container-content">
                                Looking to get started with TRON but want to level up your knowledge and flatten out your learning curve? Whether you're a complete newcomer to blockchain technology or a seasoned developer, our tutorials offer step-by-step guidance on everything from onboarding into the TRON ecosystem to building decentralized applications and more.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="homepage-starting-redline col-10 offset-1 d-none d-lg-block" />
                <div className="row no-gutters mt-5 px-5">
                    <div className="col-lg-5 offset-lg-1 offset-0 col-md-12 col-12 align-items-center text-center px-3">
                        <img src={tronbullExperiment} className="homepage-starting-icons img-fluid" alt="TRON Bull Experimenting"/>
                        <div className="homepage-starting-container text-left">
                            <div className="homepage-starting-container-header">TRON Experimentation</div>
                            <div className="homepage-starting-container-content">
                                TRON’s low barriers to entry make it easy for everyone, regardless of experience level, to interact with our platform and discover the power of blockchain technology. We offer a user-friendly interface that allows you to start experimenting with our blockchain technology right away. Whether you're looking to build decentralized applications, participate in staking, or explore our ecosystem, you can learn by doing with TRON.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-12 align-items-center text-center px-3">
                        <img src={tronbullPlay} className="homepage-starting-icons img-fluid" alt="TRON Bull Set Up Local Environment"/>
                        <div className="homepage-starting-container text-left">
                            <div className="homepage-starting-container-header">TRON TestNet</div>
                            <div className="homepage-starting-container-content">
                                TRON’s impressive ecosystem provides easy access to the TRON TestNet – a private test network that we maintain, so developers can focus on what matters most: innovation and implementation. Builders looking for a stable environment to test and refine their projects will discover a user-friendly platform on which to deploy, test, and deliver blockchain solutions, without worrying about the complexities of network management.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row no-gutters mt-5 p-5">
                <div className="col-lg-5 offset-lg-1 offset-0 col-md-6 col-12 align-items-center">
                    <div className="homepage-tronpedia-header">TRONPEDIA</div>
                    <div className="homepage-tronpedia-content mt-3 w-75">The world of cryptocurrency can be overwhelming. That's why TRON created a comprehensive resource to help newcomers and seasoned users navigate through the complex jargon. Our platform can serve as the first block or the next block in your blockchain journey, equipping you with the knowledge and tools to fully engage in decentralized commerce and community. Find what you’re looking for regarding crypto, blockchain, DeFi, web3, stablecoins, NFTs, or GameFi with TRONpedia. </div>
                    <div className="w-100 my-5 d-flex flex-wrap justify-content-between">
                        <img src={defi} className="homepage-tronpedia-icons" alt="tron dao defi"/>
                        <img src={gamefi} className="homepage-tronpedia-icons" alt="tron dao web3"/>
                        <img src={web3} className="homepage-tronpedia-icons" alt="tron dao web3"/>
                        <img src={nft} className="homepage-tronpedia-icons" alt="tron dao nft"/>
                        <img src={coins} className="homepage-tronpedia-icons" alt="tron dao coins"/>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 align-items-center">
                    <div className="homepage-tronpedia-tronbull text-center">
                        <img src={tronbullWave} className="homepage-tronpedia-tronbull-icon img-fluid" alt="tron bull wave"/>
                    </div>
                    <div className="homepage-tronpedia-learn-more text-center">
                        <a href="/tronpedia" id="homepage-button-tronpedia-learn-more">
                            <button className="homepage-tronpedia-learn-more-button mt-3">
                                > LEARN MORE
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <EmailCapture/>
        </div>
    );
};


export default Index;
